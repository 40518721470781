const Constant = {
  defaultApiUrl: `https://api.ulps.ultinous.com/default`,
  replaceDelimiter: '@%%@',
  apiUrl: "@%%@ULPS_API_URL@%%@",
  fileName: "licence.json",
  leftColumnSize: 2,
}

Constant.apiUrl = Constant.apiUrl.includes(Constant.replaceDelimiter) ? Constant.defaultApiUrl : Constant.apiUrl;

export default Constant;
